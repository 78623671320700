import { parse } from 'graphql';
import { print } from 'graphql/language/printer';

// https://github.com/dotansimha/graphql-code-generator/issues/3063
export const removeDuplicateFragments = (query: string): string => {
  const ast = parse(query);

  const seen: string[] = [];

  const newDefinitions = ast.definitions.filter((definition) => {
    if (definition.kind !== 'FragmentDefinition') {
      return true;
    }

    const id = `${definition.name.value}-${definition.typeCondition.name.value}`;
    const haveSeen = seen.includes(id);

    seen.push(id);

    return !haveSeen;
  });

  const newAst = {
    ...ast,
    definitions: newDefinitions,
  };

  return print(newAst);
};
