import { gql } from 'data/graphql/utils/gql';
import { fImage } from '../fragments';

const GET_EMPLOYEE = gql`
  query getEmployee($locale: String!, $salesforceUserId: String!) {
    employeeCollection(
      locale: $locale
      limit: 1
      where: { salesforceUserId: $salesforceUserId }
    ) {
      __typename
      items {
        firstname
        lastname
        jobTitle
        profileImage {
          ...fImage
        }
        signatureImage {
          ...fImage
        }
        email
        whatsapp
        motto
      }
    }
  }

  ${fImage}
`;

export default GET_EMPLOYEE;
