import clsx from 'clsx';
import Image from 'components/ui/Image';
import styles from './Thumbnail.module.scss';

type Props = {
  src: string;
  alt: string;
  size: number;
  shape: 'square' | 'circle';
  className?: string;
};

const Thumbnail = ({
  src,
  alt,
  size,
  shape,
  className,
}: Props): JSX.Element => {
  return (
    <div
      className={clsx(
        styles.container,
        shape === 'square' && styles.square,
        shape === 'circle' && styles.circle,
        className,
      )}
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
    >
      <Image
        className={styles.image}
        src={src}
        alt={alt}
        width={size}
        height={size}
        contentful
        priority
      />
    </div>
  );
};

export default Thumbnail;
