import GET_EMPLOYEE from 'data/graphql/queries/employee';
import { getContentfulLocale } from 'utils/locale';
import { fetchContentfulGql } from 'services/contentful';
import type { Employee } from 'types/Employee';

export const findEmployee = async (
  locale: string,
  salesforceUserId: string,
): Promise<Employee | null> => {
  const contentfulLocale = getContentfulLocale(locale);

  const gqResponse = await fetchContentfulGql({
    query: GET_EMPLOYEE,
    variables: {
      locale: contentfulLocale,
      salesforceUserId,
    },
  });

  return gqResponse.data.employeeCollection.items[0];
};
